import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import Autoplay from "embla-carousel-autoplay";
import { RiArrowRightCircleFill, RiArrowRightLine } from "react-icons/ri";

import brand1Img from "~/assets/images/pages/homepage/brand-1.jpg";
import brand2Img from "~/assets/images/pages/homepage/brand-2.jpg";
import brand3Img from "~/assets/images/pages/homepage/brand-3.jpg";
import category1 from "~/assets/images/pages/homepage/category-1.jpg";
import category2 from "~/assets/images/pages/homepage/category-2.jpg";
import category3 from "~/assets/images/pages/homepage/category-3.jpg";
import category4 from "~/assets/images/pages/homepage/category-4.jpg";
import identity1Img from "~/assets/images/pages/homepage/identity-1.jpg";
import identity2Img from "~/assets/images/pages/homepage/identity-2.png";
import identity3Img from "~/assets/images/pages/homepage/identity-3.png";
import signupBannerLarge from "~/assets/images/pages/homepage/signup-banner-large.jpg";
import signupBannerMedium from "~/assets/images/pages/homepage/signup-banner-medium.jpg";
import signupBannerSmall from "~/assets/images/pages/homepage/signup-banner-small.jpg";
import { ErrorComponent } from "~/components/error-boundary";
import { NewsletterBlock } from "~/components/newsletter-block";
import { Button } from "~/components/ui/button";
import { Card } from "~/components/ui/cards/card";
import {
  CommonCard,
  CommonCardButtons,
  CommonCardDescription,
  CommonCardImage,
  CommonCardTitle,
} from "~/components/ui/cards/common-card";
import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "~/components/ui/carousels/carousel";
import { CommonCardCarousel } from "~/components/ui/carousels/common-card-carousel";
import { ProductCardCarousel } from "~/components/ui/carousels/product-card-carousel";
import { Image } from "~/components/ui/image";
import { PageContainer } from "~/components/ui/layout/page-container";
import { Wrapper } from "~/components/ui/layout/wrapper";
import { Heading, Link, Text } from "~/components/ui/typography";
import { getToolsAndServices } from "~/models/cms.server";
import { getProducts } from "~/models/product.server";
import { getSession } from "~/models/session.server";
import { getSlides } from "~/models/slides.server";
import { useRootMatchData } from "~/root";
import { analytics } from "~/utils/analytics";
import { handleError } from "~/utils/errors.server";
import { getSeoMeta } from "~/utils/seo";

export const meta: MetaFunction = (metaArgs) => {
  const rootLoaderData = metaArgs?.matches?.find((match) => match.id === "root")?.data as any;
  const appUrl: string | null = rootLoaderData?.ENV?.VITE_APP_URL || null;

  return getSeoMeta(metaArgs, {
    title: `Powr Connect : Fournisseur en photovoltaïque et panneaux solaires`,
    description: `Découvrez nos solutions photovoltaïques pour professionnels:  modules et panneaux photovoltaïques, onduleurs solaires, systèmes de fixation, batteries et accessoires`,
    structuredData: {
      "@type": "WebSite",
      name: "Powr Connect",
      potentialAction: {
        "@type": "SearchAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: `${appUrl}/recherche?search={search_term_string}`,
        },
        "query-input": "required name=search_term_string",
      },
    },
  });
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  try {
    const session = await getSession({ request });
    const recentlyViewedProductIds = session.get("recentlyViewedProductIds") || null;

    const [
      slides,
      productsInPromo,
      productsNew,
      recentlyViewedProducts,
      productsFavorites,
      toolsAndServices,
    ] = await Promise.all([
      getSlides({ request, page: 1, itemsPerPage: 10000 }),
      getProducts({
        flagCurrentPromo: true,
        request,
      }),
      getProducts({
        flagNew: true,
        request,
      }),
      recentlyViewedProductIds && recentlyViewedProductIds.length > 0
        ? getProducts({
            ids: recentlyViewedProductIds,
            request,
          })
        : null,
      getProducts({
        flagFavorites: true,
        request,
      }),
      getToolsAndServices({ isOnHomePage: true, request }),
    ]);

    return json({
      slides: slides.data,
      productsInPromo: productsInPromo.data,
      productsNew: productsNew.data,
      recentlyViewedProducts: recentlyViewedProducts?.data || null,
      productsFavorites: productsFavorites.data,
      toolsAndServices: toolsAndServices.data,
    });
  } catch (error) {
    return handleError(error, {
      slides: null,
      productsInPromo: null,
      productsNew: null,
      recentlyViewedProducts: null,
      productsFavorites: null,
      toolsAndServices: null,
    });
  }
};

const HomePage = () => {
  const rootData = useRootMatchData();
  const data = useLoaderData<typeof loader>();
  const slides = data?.slides
    ?.filter((slide) =>
      rootData?.contact?.id ? slide.userType === "logged" : slide.userType === "anonymous"
    )
    .filter((slide) => !!slide.image)
    .sort((a, b) => a.position - b.position);

  return (
    <PageContainer>
      <Wrapper className="relative">
        {slides && slides.length > 0 ? (
          <Carousel
            opts={{
              loop: true,
              align: "center",
              breakpoints: {
                "(min-width: 640px)": { align: "start" },
              },
            }}
            plugins={[
              Autoplay({
                delay: 3000,
                stopOnInteraction: false,
                stopOnMouseEnter: true,
              }),
            ]}
          >
            <CarouselPrevious className="left-8 hidden translate-x-0 lg:flex" />
            <CarouselContent className="-ml-0" containerClassName="rounded-xl">
              {slides.map((slide) => (
                <CarouselItem key={slide.id} className="relative overflow-hidden rounded-xl pl-0">
                  <Image
                    src={slide.imageMobile || slide.image}
                    responsive={{
                      sm: slide.image,
                    }}
                    alt={slide.title}
                    className="aspect-[5/6] w-full object-cover object-left sm:aspect-[14/5] sm:object-center"
                  />
                  <div className="absolute inset-0 flex h-full w-full items-end justify-center p-8 sm:justify-start sm:px-[8%] sm:py-[4%]">
                    {slide.buttonText && slide.url ? (
                      <Button
                        as="link"
                        to={slide.url}
                        target={slide.target === "_blank" ? "_blank" : undefined}
                        rel={slide.target === "_blank" ? "noopener noreferrer" : undefined}
                        onClick={() =>
                          analytics.event({
                            action: "click",
                            data: {
                              event_category: "slider-accueil",
                              event_label: slide?.title || "",
                            },
                          })
                        }
                        icon={<RiArrowRightLine />}
                        iconPosition="right"
                        variant="outline"
                        className="border-2 border-white text-white"
                      >
                        {slide.buttonText}
                      </Button>
                    ) : null}
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselNext className="right-8 hidden translate-x-0 lg:flex" />

            <CarouselDots className="lg:hidden" />
          </Carousel>
        ) : null}

        {!rootData?.contact ? (
          <Card className="relative mt-6 grid overflow-hidden border-none px-[8%] py-8 text-center shadow-none sm:grid-cols-2 sm:text-left">
            <Image
              src={signupBannerSmall}
              responsive={{
                md: signupBannerLarge,
                sm: signupBannerMedium,
              }}
              alt="Powr Connect, inscription"
              className="object-cover max-sm:object-[40%]"
              pictureClassName="absolute inset-0"
            />
            <div className="relative">
              <Text size="2xl" className="font-normal">
                Vous aussi
              </Text>
              <Text size="2xl" className="font-semibold">
                Devenez un Héros de la Transition
              </Text>
              <Button
                as="link"
                to="/inscription"
                icon={<RiArrowRightCircleFill />}
                iconPosition="right"
                className="mt-4"
              >
                Créer mon compte
              </Button>
              <Text size="sm" className="mt-2">
                Vous avez déjà un compte ?{" "}
                <Link to="/connexion" className="text-primary-800">
                  Connectez-vous
                </Link>
              </Text>
            </div>
            <div />
          </Card>
        ) : null}

        <Heading as="h1" className="mt-8">
          Fournisseur et grossiste en panneaux solaires et onduleurs.
        </Heading>
        <Text as="p" className="mt-4">
          Chez Powr Connect, nous nous engageons à fournir des solutions d&apos;énergie solaire de
          haute qualité pour répondre aux besoins croissants en matière de durabilité et
          d&apos;efficacité énergétique. En tant que fournisseur et grossiste en panneaux solaires
          et en onduleurs, nous offrons une gamme complète de produits et services pour les
          entreprises et les collectivités.
        </Text>

        {data?.productsInPromo && data?.productsInPromo?.length > 0 ? (
          <>
            <Heading as="h2" className="mt-16">
              Les promos du moment !
            </Heading>
            <Text as="p" muted className="mt-2">
              Profitez des super promotions en cours et faites des économies sur vos produits
              préférés !
            </Text>
            <ProductCardCarousel
              products={data.productsInPromo}
              comparator={rootData?.comparator || null}
              contact={rootData?.contact || null}
              className="mt-4"
            />
          </>
        ) : null}

        {data?.recentlyViewedProducts && data?.recentlyViewedProducts?.length > 0 ? (
          <>
            <Heading as="h2" className="mt-16">
              Vous avez l’oeil ! Vos produits vus récemment.
            </Heading>
            <Text as="p" muted className="mt-2">
              Ces produits vous ont séduit dernièrement. Ils n&apos;attendent que vous.
            </Text>
            <ProductCardCarousel
              products={data.recentlyViewedProducts}
              comparator={rootData?.comparator || null}
              contact={rootData?.contact || null}
              className="mt-4"
            />
          </>
        ) : null}

        <Heading as="h2" className="mt-16">
          Notre identité, pas si secrète !
        </Heading>
        <Text as="p" muted className="mt-2">
          Grâce à nos 15 années d’expérience, nous sommes devenus leader français de la distribution
          de matériel photovoltaïque et un acteur incontournable sur la chaîne de valeur
          photovoltaïque.
        </Text>
        <CommonCardCarousel
          className="mt-4"
          items={[
            <CommonCard className="col-span-2" key="identity-1">
              <CommonCardImage
                src={identity1Img}
                alt="POwR Connect, fournisseur n°1 en solutions photovoltaïques pour les professionnels."
              />
              <CommonCardTitle as="h3">
                POwR Connect, fournisseur n°1 en solutions photovoltaïques pour les professionnels.
              </CommonCardTitle>
              <CommonCardDescription>
                L’entreprise POwR Connect, une filiale de POwR Group, est le partenaire de confiance
                des professionnels du photovoltaïque pour les marchés français et suisse. <br />
                <br />
                Spécialisés dans le secteur commercial et industriel (C&I), ainsi que dans le marché
                résidentiel, nous proposons une gamme complète de solutions solaires et de matériel
                PV, ...
              </CommonCardDescription>
              <CommonCardButtons>
                <Button as="link" to="/a-propos/qui-sommes-nous">
                  Lire la suite
                </Button>
              </CommonCardButtons>
            </CommonCard>,
            <CommonCard key="identity-2">
              <CommonCardImage
                src={identity2Img}
                alt="La plus large offre du marché PV avec près de 1000 produits en ligne."
                className="object-contain p-4"
              />
              <CommonCardDescription className="mt-0 pt-4">
                L&apos;offre la plus large du marché PV avec près de 1000 produits en ligne :
                accédez aux meilleurs prix et aux stocks en temps réel.
              </CommonCardDescription>
            </CommonCard>,
            <CommonCard key="identity-3">
              <CommonCardImage
                src={identity3Img}
                alt="Une expertise reconnue et avérée : une équipe de commerciaux terrain et de technico-commerciaux vous accompagnent dans l'étude de vos projets photovoltaiques."
                className="object-contain p-4"
              />
              <CommonCardDescription className="mt-0 pt-4">
                Une expertise reconnue et avérée : une équipe de commerciaux terrain et de
                technico-commerciaux vous accompagnent dans l&apos;étude de vos projets PV.
              </CommonCardDescription>
            </CommonCard>,
          ]}
        />

        <Heading as="h2" className="mt-16">
          Nos Héros favoris, les marques du moment !
        </Heading>
        <Text as="p" muted className="mt-2">
          Nos partenaires fabricants sont des as de l&apos;innovation, de la fiabilité et de la
          performance. Profitez-en !
        </Text>
        <CommonCardCarousel
          className="mt-4"
          items={[
            <CommonCard className="col-span-2" key="brand-1">
              <CommonCardImage
                src={brand1Img}
                alt="DUONERGY, les modules POwR Connect"
                className="object-left"
              />
              <CommonCardTitle as="h3">DUONERGY, les modules POwR Connect</CommonCardTitle>
              <CommonCardDescription>
                DUONERGY, marque de panneaux solaires de POwR Connect, propose des modules solaires
                photovoltaïques bifaciaux de haute qualité. Les modules Duonergy allient technologie
                de pointe et qualité supérieure pour garantir une production d’énergie optimale.
                Conçus pour les installations résidentielles, industrielles ou tertiaires, ils sont
                équipés de la technologie N-Type.
              </CommonCardDescription>
              <CommonCardButtons>
                <Button as="link" to="/modules-photovoltaiques?marques=duonergy">
                  Voir les produits DUONERGY
                </Button>
              </CommonCardButtons>
            </CommonCard>,
            <CommonCard key="brand-2">
              <CommonCardImage
                src={brand2Img}
                alt="Obtenez du cashback SMA dès maintenant"
                className="object-right"
              />
              <CommonCardTitle as="h3">
                SMA, leader en solutions énergétiques renouvelables
              </CommonCardTitle>
              <CommonCardDescription className="mt-0 pt-4">
                SMA Solar Technology, pionnier et leader dans l&apos;ingénierie des systèmes
                photovoltaïques depuis près de 40 ans, offre des solutions complètes et évolutives
                pour un approvisionnement énergétique décentralisé et renouvelable.
              </CommonCardDescription>
              <CommonCardButtons>
                <Button as="link" to="/onduleurs-solaires?marques=sma">
                  Voir les produits SMA
                </Button>
              </CommonCardButtons>
            </CommonCard>,
            <CommonCard key="brand-3">
              <CommonCardImage
                src={brand3Img}
                alt="Les carports solaires SHAPE"
                className="object-left"
              />
              <CommonCardTitle as="h3">Les carports solaires SHAPE</CommonCardTitle>
              <CommonCardDescription className="mt-0 pt-4">
                Shape se positionne à l’avant-garde des solutions énergétiques renouvelables, avec
                développement de carports photovoltaïques.
              </CommonCardDescription>
              <CommonCardButtons>
                <Button as="link" to="/ombrieres-et-carports">
                  Voir les produits SHAPE
                </Button>
              </CommonCardButtons>
            </CommonCard>,
          ]}
        />

        {data?.productsNew && data?.productsNew?.length > 0 ? (
          <>
            <Heading as="h2" className="mt-16">
              Nouveaux produits photovoltaïques
            </Heading>
            <Text as="p" muted className="mt-2">
              POwR Connect ajoute de nouvelles références à son catalogue en permanence,
              découvrez-en quelques-unes ici.
            </Text>
            <ProductCardCarousel
              products={data.productsNew}
              comparator={rootData?.comparator || null}
              contact={rootData?.contact || null}
              className="mt-4"
            />
          </>
        ) : null}

        <NewsletterBlock className="z-0 mt-16" />

        {data?.productsFavorites && data?.productsFavorites?.length > 0 ? (
          <>
            <Heading as="h2" className="mt-16">
              Les coups de cœur !
            </Heading>
            <Text as="p" muted className="mt-2">
              Nos POwR Troopers ont flashé sur ces produits, laissez-vous tenter également.
            </Text>
            <ProductCardCarousel
              products={data.productsFavorites}
              comparator={rootData?.comparator || null}
              contact={rootData?.contact || null}
              className="mt-4"
            />
          </>
        ) : null}

        {data?.toolsAndServices && data?.toolsAndServices?.length > 0 ? (
          <>
            <Heading as="h2" className="mt-16">
              Nos services
            </Heading>
            <Text as="p" muted className="mt-2">
              La mission de POwR Connect est aussi de vous faciliter le travail au quotidien, avec
              des outils et services, accessibles en toute autonomie.
            </Text>
            <CommonCardCarousel
              className="mt-4"
              items={data.toolsAndServices
                .sort((a, b) => a.displayOrderOnHomePage - b.displayOrderOnHomePage)
                .map((toolOrService) => (
                  <CommonCard className="col-span-2" key={toolOrService?.id}>
                    <CommonCardImage src={toolOrService?.image} alt={toolOrService?.title} />
                    <CommonCardTitle as="h3">{toolOrService?.title}</CommonCardTitle>
                    <CommonCardDescription>{toolOrService?.content}</CommonCardDescription>
                    <CommonCardButtons>
                      <Button
                        as="link"
                        to={toolOrService?.ctaUrl}
                        target={toolOrService?.ctaTarget === "_blank" ? "_blank" : undefined}
                        rel={
                          toolOrService?.ctaTarget === "_blank" ? "noopener noreferrer" : undefined
                        }
                      >
                        {toolOrService?.ctaTitle}
                      </Button>
                    </CommonCardButtons>
                  </CommonCard>
                ))}
            />
          </>
        ) : null}

        <Heading as="h2" className="mt-16">
          Nos familles phares !
        </Heading>
        <Text as="p" muted className="mt-2">
          Retrouvez nos familles de produits Superstars.
        </Text>
        <CommonCardCarousel
          className="mt-4"
          items={[
            <CommonCard className="col-span-2" key="category-1">
              <CommonCardImage src={category1} alt="Modules photovoltaïques" />
              <CommonCardTitle as="h3">Modules photovoltaïques</CommonCardTitle>
              <CommonCardDescription>
                Nos panneaux solaires haut de gamme vous garantissent des performances optimales
                pour chaque projet.
              </CommonCardDescription>
              <CommonCardButtons>
                <Button as="link" to="/modules-photovoltaiques">
                  Voir les Modules
                </Button>
              </CommonCardButtons>
            </CommonCard>,

            <CommonCard className="col-span-2" key="category-2">
              <CommonCardImage src={category2} alt="Onduleurs & micro-onduleurs" />
              <CommonCardTitle as="h3">Onduleurs & micro-onduleurs</CommonCardTitle>
              <CommonCardDescription>
                Nos onduleurs PV assurent une alimentation fiable pour des installations solaires de
                qualité.
              </CommonCardDescription>
              <CommonCardButtons>
                <Button as="link" to="/onduleurs-solaires">
                  Voir les Onduleurs
                </Button>
              </CommonCardButtons>
            </CommonCard>,

            <CommonCard className="col-span-2" key="category-3">
              <CommonCardImage src={category3} alt="Systèmes de fixations" />
              <CommonCardTitle as="h3">Systèmes de fixations</CommonCardTitle>
              <CommonCardDescription>
                Simplifiez vos installations. Découvrez nos solutions de montage robustes pour une
                mise en place rapide et sécurisée de vos panneaux solaires.
              </CommonCardDescription>
              <CommonCardButtons>
                <Button as="link" to="/systemes-de-fixation">
                  Voir les Systèmes de fixation
                </Button>
              </CommonCardButtons>
            </CommonCard>,

            <CommonCard className="col-span-2" key="category-4">
              <CommonCardImage src={category4} alt="Batteries de stockage" />
              <CommonCardTitle as="h3">Batteries de stockage</CommonCardTitle>
              <CommonCardDescription>
                Nos batteries performantes offrent une solution énergétique stable pour les
                professionnels.
              </CommonCardDescription>
              <CommonCardButtons>
                <Button as="link" to="/batteries-solaires">
                  Voir les Batteries
                </Button>
              </CommonCardButtons>
            </CommonCard>,
          ]}
        />
      </Wrapper>
    </PageContainer>
  );
};

export default HomePage;

export const ErrorBoundary = () => {
  return <ErrorComponent />;
};
